html,
body {
  height: 100%;
}
#app {
  height: 100%;
}
#app > div {
  height: 100%;
}
#app .search-input .input-group input:focus {
  outline: none;
  border: 1px solid #ccc;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
}
#app .search-input .input-group .input-group-addon {
  color: #fff;
  background-color: #231f20;
  border: 1px solid #231f20;
}
#app .search-input .input-group .form-control {
  border: 1px solid #231f20;
  box-shadow: none;
  -webkit-box-shadow: none;
}
#app .search-input .input-group .form-control,
#app .search-input .input-group .input-group-addon {
  border-radius: 0;
}
.alert-danger,
.alert-info,
.alert-success,
.alert-warning {
  background-image: none;
  border-radius: 0;
}
a,
a:hover,
a:active,
a:focus,
a:visited {
  color: #606060;
}
.breadcrumb {
  border-radius: 0;
}
.btn.btn-link {
  text-decoration: underline;
  color: #777;
}
.btn.btn-link:hover {
  color: #333;
}
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px 6px;
  border-radius: 0 6px 6px 6px;
}
.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #cccccc;
  margin-top: 5px;
  margin-right: -10px;
}
.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}
.dropdown-submenu.pull-left {
  float: none;
}
.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}
.dropdown-submenu:hover > a:after {
  border-left-color: #606060;
}
.dropdown-menu li > a:hover,
.dropdown-menu li > a:focus,
.dropdown-submenu:hover > a {
  color: #606060;
  text-decoration: none;
  background-color: #f5f5f5;
  background-image: -moz-linear-gradient(top, #f5f5f5, #f5f5f5);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f5f5f5), to(#f5f5f5));
  background-image: -webkit-linear-gradient(top, #f5f5f5, #f5f5f5);
  background-image: -o-linear-gradient(top, #f5f5f5, #f5f5f5);
  background-image: linear-gradient(to bottom, #f5f5f5, #f5f5f5);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f5f5f5', endColorstr='#f5f5f5', GradientType=0);
  /*[class^="icon-"],
	[class*=" icon-"] {
	    background-image: url("/Content/images/glyphicons-halflings.png");
	}*/
}
.dropdown-menu .active > a,
.dropdown-menu .active > a:hover {
  color: #606060;
  text-decoration: none;
  background-color: #f5f5f5;
  background-image: -moz-linear-gradient(top, #f5f5f5, #f5f5f5);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f5f5f5), to(#f5f5f5));
  background-image: -webkit-linear-gradient(top, #f5f5f5, #f5f5f5);
  background-image: -o-linear-gradient(top, #f5f5f5, #f5f5f5);
  background-image: linear-gradient(to bottom, #f5f5f5, #f5f5f5);
  background-repeat: repeat-x;
  outline: 0;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f5f5f5', endColorstr='#f5f5f5', GradientType=0);
  /*[class^="icon-"],
	[class*=" icon-"] {
	    background-image: url("/Content/images/glyphicons-halflings.png");
	}*/
}
.user-form .alert {
  border-radius: 0;
  padding: 8px 35px 8px 14px;
}
.user-form .help-block.error {
  color: #b94a48;
}
.user-form ul.form-help-list {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}
.user-form ul.form-help-list li {
  font-size: 0.8em;
  text-decoration: underline;
  margin-bottom: 5px;
}
.studio-form .flex-preview-inline {
  display: flex;
}
.studio-form .flex-preview-inline .preview {
  flex-grow: 1;
  margin-right: 15px;
}
.studio-form .flex-preview-inline .controls {
  flex-basis: 100%;
}
.studio-form .flex-preview-inline .controls > div {
  margin-top: 10px;
}
.studio-form .flex-preview-inline .controls > div:first-child {
  margin-top: 0;
}
.studio-form .form-group {
  margin-bottom: 10px;
}
.studio-form .form-control:focus {
  border-color: #c5c5c4;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(77, 77, 77, 0.2);
}
.studio-form fieldset {
  margin-bottom: 5px;
}
.studio-form fieldset > legend {
  font-size: 18px;
  font-weight: bold;
  color: #c5c5c4;
  margin-bottom: 10px;
}
.studio-form fieldset .control-label {
  color: #c5c5c4;
}
.image-viewer,
.zoomable-image-viewer {
  background-color: #F0F0F0;
}
.zoomable-image-viewer .zoom-slider {
  top: 5px;
  left: 5px;
  padding: 7px;
  background-color: rgba(197, 197, 196, 0.8);
}
.modal-dialog .modal-content {
  border-radius: 0;
}
.studio-dialog .modal-header {
  background-image: none;
  background-color: #c5c5c4;
  padding: 10px 15px;
  border-width: 0;
}
.studio-dialog .modal-header button.close {
  padding-top: 3px;
  color: #777;
  opacity: 1;
  text-shadow: 0 0 0 #fff;
}
.studio-dialog .modal-header .modal-title {
  color: #fff;
  font-weight: bold;
}
.studio-dialog .modal-header .modal-title small {
  color: #fff;
  margin-left: 5px;
  font-size: 85%;
}
.studio-dialog .modal-footer {
  padding: 10px 15px;
}
.nav-tabs > li > a {
  border-radius: 0;
}
.nav-tabs .open .dropdown-toggle > a,
.nav-pills .open .dropdown-toggle > a,
.nav > li.dropdown.open > a,
.nav-tabs .open .dropdown-toggle > a:hover,
.nav-pills .open .dropdown-toggle > a:hover,
.nav > li.dropdown.open > a:hover,
.nav-tabs .open .dropdown-toggle > a:focus,
.nav-pills .open .dropdown-toggle > a:focus,
.nav > li.dropdown.open > a:focus {
  background-color: #eee;
  border-color: #eee;
}
.nav .dropdown-toggle:hover .caret,
.nav .dropdown-toggle .caret {
  border-top-color: #606060;
  border-bottom-color: #606060;
}
.nav-pills > .active > a,
.nav-pills > .active > a:hover {
  background-color: #F80;
  font-weight: bold;
  color: #fff;
}
.pager li > a,
.pager li > span {
  border-radius: 0;
}
.pagination {
  border-radius: 0;
}
.pagination > li {
  display: inline-block;
}
.pagination > li > a,
.pagination > li > span {
  display: inline-block;
  float: none;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #c5c5c4;
  border-color: #c5c5c4;
}
.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  color: #606060;
  border-color: #c5c5c4;
}
.pagination > li > a,
.pagination > li > span {
  font-weight: normal;
  color: #606060;
  border-color: #c5c5c4;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  color: #606060;
  background-color: #eee;
}
.panel-default,
.panel-group .panel {
  border-radius: 0;
  border-color: #c5c5c4;
}
.panel-default .panel-heading,
.panel-group .panel .panel-heading {
  color: #fff;
  border-radius: 0;
  background-image: none;
  background-color: #c5c5c4;
  border-color: #c5c5c4;
  font-weight: bold;
}
.panel-default .panel-footer,
.panel-group .panel .panel-footer {
  background-image: none;
  background-color: #eee;
}
.panel-default .panel-title,
.panel-group .panel .panel-title {
  border-top: 1px solid #c5c5c4;
  font-weight: bold;
}
.panel-group .info-panel .panel-title a {
  cursor: pointer;
}
.panel-group .info-panel .panel-title a:hover {
  color: #fff;
}
.info-panel {
  max-width: 560px;
  margin: 0 auto;
}
.info-panel .panel-body {
  max-height: 400px;
  overflow-y: auto;
}
.info-panel .panel-body a {
  font-weight: bold;
  text-decoration: underline;
}
.react-autosuggest__container--open .react-autosuggest__suggestions-container {
  display: block;
  position: absolute;
  top: 40px;
  width: 280px;
  border: 1px solid #ccc;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 10;
  right: 0;
}
.react-autosuggest__suggestions-list {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}
.react-autosuggest__suggestions-list li {
  border-top: 1px solid #ccc;
}
.react-autosuggest__suggestions-list li:first-child {
  border-top: 0;
}
.selectable-text {
  user-select: none;
}
.swatch-frame-editor-wrapper {
  background-color: #F0F0F0;
}
.swatch-frame-editor-wrapper .swatch-frame-editor {
  margin: auto;
  user-select: none;
}
.well {
  border-radius: 0;
  box-shadow: none;
  background-image: none;
}
